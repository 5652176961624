import PageViewed from "~/models/Events/PageViewed";
import InitializedCheckout from "~/models/Events/InitializedCheckout";
import PickyEaterPopupActivated from "./PickyEaterPopupActivated";
import PickyEaterPopupCTAClicked from "./PickyEaterPopupCTAClicked";

export default [
  new PickyEaterPopupActivated(),
  new PickyEaterPopupCTAClicked(),
  new PageViewed(),
  new InitializedCheckout(),
];
