import camelCase from "lodash/camelCase";
import { formatDate as dnsFormatDate, parseISO } from "date-fns";

export const formatDate = (dateString: string) => new Intl.DateTimeFormat("en-US", {
  month: "long",
  day: "numeric",
  year: "numeric",
})
  .format(new Date(dateString))
  .toUpperCase();

export const formatIsoDate = (dateString: string, format: string) => {
  const isoDate = parseISO(dateString);
  return dnsFormatDate(isoDate, format);
};

export const maybePluralize = (count: number, noun: any, suffix = "s") =>
  `${noun}${count !== 1 ? suffix : ""}`;

export const formatMoney = (money: number, decimals = 2) => {
  if (Number(money) !== money && Number.isNaN(money)) {
    throw new Error("Money must be a number");
  }

  return `${money < 0 ? "-" : ""}$${Math.abs(money).toFixed(decimals)}`;
};

export const allKeysToCamelCase = (
  object: { [s: string]: unknown } | ArrayLike<unknown>
) => Object.entries(object).reduce((carry: { [key: string]: unknown }, [key, value]) => {
  carry[camelCase(key)] = value;
  return carry;
}, {});

export const getNumberSuffix = (num: number) => {
  const th = "th";
  const rd = "rd";
  const nd = "nd";
  const st = "st";

  if (num === 11 || num === 12 || num === 13) return th;

  const lastDigit = num.toString().slice(-1);

  switch (lastDigit) {
    case "1":
      return st;
    case "2":
      return nd;
    case "3":
      return rd;
    default:
      return th;
  }
};

/**
 * Takes an object whose keys may be nested with dots, eg {'on.two.three': 'value', four: 'other value'}
 * and returns a new version with the dotted keys separated and nested, eg:
 * { one: { two: { three: 'value' } }, four: 'other value' }
 *
 * @param {{[index: string]: any}} dottedObject
 * @returns {{[index: string]: any}}
 */
export const undot = (dottedObject: { [x: string]: any }) => {
  function addSubFields(
    fields: string | any[],
    value: any,
    obj: { [x: string]: any }
  ) {
    const head = fields[0];
    const tail = fields.slice(1);

    if (tail.length > 0) {
      obj[head] = addSubFields(tail, value, obj[head] ?? {});
    } else {
      obj[head] = value;
    }

    return obj;
  }

  const undottedObject = {};
  for (const field in dottedObject) {
    if (Object.prototype.hasOwnProperty.call(dottedObject, field)) {
      addSubFields(field.split("."), dottedObject[field], undottedObject);
    }
  }

  return undottedObject;
};

export const formatPrice = (price: number) => {
  if (Number(price) !== price && Number.isNaN(price)) {
    throw new Error(`Money must be a number ${price}`);
  }

  return `${price < 0 ? "-" : ""}$${Math.abs(price).toFixed(2)}`;
};

export const roundPriceIfDecimal = (price: number) => {
  if (!price) {
    return null;
  }
  return Number.isInteger(price) ? `$${price}` : formatPrice(price);
};

export const findGetParameter = (parameterName: string): string|null => {
  let result = null;
  let tmp = [];

  window.location.search
    .substr(1)
    .split("&")
    .forEach((item) => {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
};

export const findBooleanGetParameter = (name: string) => {
  const value = findGetParameter(name);
  return !(value === null || value === "0" || value === "false");
};

export const findFirstStringProperty = (obj: { [key: string]: any }): null | any => {
  // Check if the current object is not actually an object (base case)
  if (typeof obj !== "object" || obj === null) {
    return null;
  }

  // Iterate over all properties of the current object
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Check if the property value is a string
      if (typeof obj[key] === "string") {
        return obj[key];
      }
      // If the property value is an object, recurse
      const result = findFirstStringProperty(obj[key]);
      if (result) {
        return result;
      }
    }
  }

  // Return null if no string property is found
  return null;
};

export function wrapInSlashes(input: string) {
  return input.replace(/^\/?([^/]+(?:\/[^/]+)*)\/?$/, "/$1/");
}

export function removeTrailingSlashes(path: string): string {
  return path.replace(/\/+$/, "");
}
