import Cookies from "js-cookie";
import { DOMAIN } from "@/constants/urls";

export const KEY_PROMO_CODE = "promo-code";
const KEY_ABANDONED_CART_ID = "abandoned-cart-id";
const KEY_IMPERSONATION_MODE = "impersonation_mode";
const KEY_SELECTED_NONPROFIT_ID = "selected-nonprofit-id";

export const saveAbandonedCartId = (abandonedCartId: string) => {
  localStorage.setItem(KEY_ABANDONED_CART_ID, abandonedCartId);
};

export const restoreAbandonedCartId = () =>
  localStorage.getItem(KEY_ABANDONED_CART_ID);

export const removeAbandonedCartId = () => {
  localStorage.removeItem(KEY_ABANDONED_CART_ID);
};

export const clearImpersonate = () => {
  Cookies.remove(KEY_IMPERSONATION_MODE, { domain: `.${DOMAIN}` });
};

export const getImpersonationMode = () => Cookies.get(KEY_IMPERSONATION_MODE);

export const saveAutoApplyPromoCodeCookies = (
  promoCode: string,
  overwrite: boolean
) => {
  Cookies.set("auto_apply_promo_code", promoCode, {
    expires: 365,
    domain: `.${DOMAIN}`,
  });
  Cookies.set("overwrite_promo_code", overwrite.toString(), {
    expires: 365,
    domain: `.${DOMAIN}`,
  });
};

export const clearAutoApplyPromoCodeCookies = () => {
  Cookies.remove("auto_apply_promo_code", { domain: `.${DOMAIN}` });
  Cookies.remove("overwrite_promo_code", { domain: `.${DOMAIN}` });
};

export const getSelectedNonProfitId = () => {
  const nonProfitId = localStorage.getItem(KEY_SELECTED_NONPROFIT_ID);
  return nonProfitId ? parseInt(nonProfitId, 10) : null;
};

export const saveSelectedNonProfitId = (nonProfitId: string) => {
  localStorage.setItem(KEY_SELECTED_NONPROFIT_ID, nonProfitId);
};
