import { defineStore } from "pinia";
import { request } from "@/services/request";
import { URL_RETRIEVE_EXPERIMENTS } from "@/constants/urls";
import { track as trackExperimentsLoaded } from "@/services/events/track/experiments/experimentsLoaded";
import type { Experiment, ExperimentParams, ExperimentResponse, ExperimentState } from "~/types/experiments";

export const useExperimentStore = defineStore("experiment", {
  state: (): ExperimentState => ({
    items: [],
    isLoaded: false,
  }),

  getters: {
    experiments: (state: ExperimentState): Experiment[] => state.items,
    hasVariant: (state: ExperimentState) => (variantCode: string): boolean =>
      state.items.some((experiment) => experiment.code === variantCode),
    isExperimentsLoaded: (state: ExperimentState): boolean => state.isLoaded,
  },

  actions: {
    async getExperiments(params: ExperimentParams = {}) {
      const onboardingStore = useOnboardingStore();
      const authStore = useAuthStore();
      
      const finalParams: ExperimentParams = {
        abandoned_cart_id: onboardingStore.abandonedCartId,
        user_id: authStore.userId,
        ...params,
      };

      const response = await request.get<ExperimentResponse>(URL_RETRIEVE_EXPERIMENTS, {
        params: finalParams,
      });

      this.setItems(response.data.data);
      this.isLoaded = true;
      trackExperimentsLoaded(response.data.data);
    },

    setItems(experiments: Experiment[]) {
      this.items = experiments;
    },
  },
});
