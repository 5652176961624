import { useRouter } from "vue-router";
import eventsManager from "~/services/events/eventsManager";
import DomainEvents from "~/models/DomainEvents";
import { restoreAbandonedCartId } from "~/services/storage";
import { randomUUID } from "uncrypto";

export default defineNuxtPlugin(() => {
  const router = useRouter();
  const domainEvents = ref(new DomainEvents());
  let lastPath: string | null = null;

  router.afterEach((to) => {
    const eventId = randomUUID();
    eventsManager.push("pageViewed", {
      urlFullPath: to.fullPath,
      conversion: { eventId }
    });

    if (lastPath === to.fullPath) {
      return;
    }

    lastPath = to.fullPath;

    if (!process.client) return;

    const abandonedCartId = restoreAbandonedCartId();
    if (!abandonedCartId) return;

    domainEvents.value.push("viewed-page", {
      url: window.location.origin + to.fullPath,
      name: to.name,
      abandonedCartId,
      parameters: { eventId }
    });
  });
});