<script lang="ts" setup>
import { useFocusTrap } from "@vueuse/integrations/useFocusTrap";

const menusStore = useMenusStore();
const onboardingStore = useOnboardingStore();
const captureEmailPopup = ref();
const { deactivate } = useFocusTrap(captureEmailPopup, {
  immediate: true,
});
onClickOutside(captureEmailPopup, () => close());

function close() {
  menusStore.SET_PENDING_CART_ITEMS([]);
  onboardingStore.UPDATE_ONBOARDING_STATE({
    pendingBestsellerGroupId: null,
  });
  menusStore.SHOW_CAPTURE_EMAIL_POPUP(false);
  deactivate();
}
</script>

<template>
  <div
    v-if="menusStore.isCaptureEmailPopupShown"
    class="tw-fixed tw-inset-0 tw-bg-nl-green-100/[0.35] tw-flex tw-flex-wrap tw-items-center tw-justify-center tw-z-50 tw-overflow-y-auto"
    data-cy="capture-email-popup"
    role="dialog"
    aria-labelledby="dialog-capture-email-title"
    @click.stop="() => {}"
  >
    <div class="tw-container tw-w-full tw-py-[50px] max-lg:tw-px-3">
      <div
        ref="captureEmailPopup"
        class="tw-max-w-[350px] lg:tw-max-w-[824px] tw-mx-auto tw-bg-nl-yellow-100 tw-rounded-[30px] lg:tw-flex tw-relative tw-overflow-hidden"
      >
        <span
          class="icon-main-times tw-text-[20px] tw-font-bold tw-cursor-pointer tw-absolute tw-right-[22px] tw-top-[22px] tw-z-10 tw-drop-shadow-[0_0_6px_white]"
          @click="close"
        ></span>
        <div
          class="max-lg:tw-hidden tw-w-[389px] tw-h-[596px] tw-relative popup-masked-img"
        >
          <img
            src="/assets/images/menu/collect-email-pop-up-bg-desktop.webp"
            class="mask tw-block"
            alt="Image"
          />
        </div>

        <div
          class="lg:tw-hidden tw-w-[350px] tw-h-[198px] tw-relative popup-masked-img-mob"
        >
          <img
            src="/assets/images/menu/collect-email-pop-up-bg-mobile.webp"
            class="mask tw-block"
            alt="Image"
          />
        </div>

        <div
          class="lg:tw-flex-1 lg:tw-flex lg:tw-items-center tw-py-5 tw-px-6 xl:tw-pr-[80px] xl:tw-pl-[49px]"
        >
          <div>
            <div
              class="tw-text-[16px] lg:tw-text-[18px] tw-leading-[1.222] tw-font-medium tw-mb-[15px] tw-text-center"
            >
              <h1
                class="tw-text-[32px] lg:tw-text-[35px] tw-leading-none tw-font-black tw-mb-1 tw-uppercase"
              >
                Let’s Get Started!
              </h1>
              <p>
                Save time and feed your kids nutritious, delicious meals &
                snacks they’ll love.
              </p>
            </div>
            <CaptureEmailFormLegacy @submitted="close" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
@import url("~/assets/css/app.css");
</style>
