<script lang="ts" setup>
import { updateBeamNonProfit } from "@/services/api/onboarding";
import { events } from "@beamimpact/web-sdk/dist/utils";
import {
  getSelectedNonProfitId,
  saveSelectedNonProfitId,
} from "@/services/storage";
import { track as trackBeamNonprofitSelected } from "./track/beamNonprofitSelected";

const onboardingStore = useOnboardingStore();

const listenToBeamChanges = () => {
  window.addEventListener(events.BeamNonprofitSelectEvent.eventName, async (event) => {
    // @ts-ignore
    const { selectedNonprofitId, nonprofitName } = event.detail;

    if (getSelectedNonProfitId() !== selectedNonprofitId) {
      await updateBeamNonProfit(selectedNonprofitId, nonprofitName, onboardingStore.abandonedCartId);
      trackBeamNonprofitSelected(selectedNonprofitId, nonprofitName);
      saveSelectedNonProfitId(selectedNonprofitId);
    }
  });
};

onMounted(() => {
  listenToBeamChanges();
});

</script>
