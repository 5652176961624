import { request } from "@/services/request";
import { URL_MENU_ITEMS } from "@/constants/urls";
import { getMenuItemStock } from "@/services/api/products";
import filter from "lodash/filter";
import get from "lodash/get";
import minBy from "lodash/minBy";
import sortBy from "lodash/sortBy";
import { defineStore } from "pinia";
import type { MenuItemInterface } from "@/types/menu";
import type { ProductInterface } from "~/types/product";
import type { BundleItem, BundlePreset } from "~/types/bundle";
import { mapProduct } from "@/services/api/data/products";

interface State {
  selectedMenuItem: MenuItemInterface | null;
  menuItems: MenuItemInterface[];
  productsList: ProductInterface[];
  stock: Record<string, ProductInterface[]>;
  pendingItemsPreset: BundlePreset | null;
  showCaptureEmailPopup: boolean;
  filteredProductCount: number | null;
  pendingCartItems: BundleItem[],
}

export const useMenusStore = defineStore("menus", {
  state: (): State => ({
    selectedMenuItem: null,
    menuItems: [],
    productsList: [],
    stock: {},
    pendingItemsPreset: null,
    showCaptureEmailPopup: false,
    filteredProductCount: null,
    pendingCartItems: [],
  }),

  getters: {
    defaultMenuItem: (state) => state.menuItems?.[0] ?? null,
    stockItemsByMenuItemId: (state) => (id: string) => get(state.stock, id, []),
    leastExpensiveProductInCategory: (state) => (categoryID: number) => {
      const products = state?.selectedMenuItem?.id
        ? filter(
          state.stock[state?.selectedMenuItem?.id],
          (product) => product.category?.id === categoryID
        )
        : [];

      return minBy(products, (product) => product.price_object.price);
    },
    isCaptureEmailPopupShown: ({ showCaptureEmailPopup }) =>
      showCaptureEmailPopup,
    hasPendingCartItems: (state) => state.pendingCartItems.length > 0,
  },

  actions: {
    SET_SELECTED_MENU_ITEM(item: MenuItemInterface) {
      this.selectedMenuItem = item;
    },
    SET_MENU_ITEMS(menuItems: MenuItemInterface[]) {
      this.menuItems = sortBy(menuItems, (item) => -item.sorting_weight);
    },
    RESET_MENU_ITEM_STOCK(id: string) {
      this.stock[id] = [];
    },
    ADD_TO_MENU_ITEM_STOCK({
      id,
      items,
    }: {
      id: string;
      items: ProductInterface[];
    }) {
      this.stock[id] = [
        ...this.stock[id].filter(
          (item) =>
            !items.some((search: { sku: string }) => search.sku === item.sku)
        ),
        ...items,
      ];
      this.productsList = [
        ...this.productsList.filter(
          (item) =>
            !items.some((search: { sku: string }) => search.sku === item.sku)
        ),
        ...items,
      ];
    },
    SET_PENDING_CART_ITEMS(items: BundleItem[]) {
      this.pendingCartItems = items;
    },
    SET_PENDING_ITEMS_PRESET(preset: BundlePreset | null) {
      this.pendingItemsPreset = preset;
    },
    SET_FILTERED_PRODUCT_COUNT(count: number) {
      this.filteredProductCount = count;
    },
    SHOW_CAPTURE_EMAIL_POPUP(value: boolean) {
      this.showCaptureEmailPopup = value;
    },
    fetchMenuItemsStock({
      menuItem,
      indicators,
      tags,
      promoCode,
    }: {
      menuItem: MenuItemInterface;
      indicators: number[];
      tags: number[];
      promoCode: string | null;
    }) {
      const onboardingStore = useOnboardingStore();
      const authStore = useAuthStore();
      this.RESET_MENU_ITEM_STOCK(menuItem.id);
      const menusToFetch = [];
      // Each doesn't wait for the promises to be fulfilled
      const newMenu = new Promise<void>(async (resolve, _reject) => {
        let hasMore = true;
        let page = 0;
        let abandonedCartId = null;

        if (!authStore.isLoggedIn) {
          abandonedCartId = onboardingStore.abandonedCartId;
        }

        while (hasMore) {
          const res = await getMenuItemStock({
            menuItem,
            indicators,
            tags,
            promoCode,
            abandonedCartId,
            page: page + 1,
            perPage: 9,
          });

          const items = res.data.data.map((item: unknown) =>
            mapProduct(item)
          ) as ProductInterface[];

          this.ADD_TO_MENU_ITEM_STOCK({
            id: menuItem.id,
            items,
          });

          const { pagination } = res.data.meta;
          hasMore = pagination.current_page < pagination.total_pages;
          page = pagination.current_page;
        }
        resolve();
      });

      menusToFetch.push(newMenu);

      return Promise.allSettled(menusToFetch);
    },
    async fetchMenuItems() {
      const onboardingStore = useOnboardingStore();
      const cartStore = useCartStore();

      const res = await request
        .get(URL_MENU_ITEMS, {
          params: {
            promoCode: cartStore?.promoCode?.code,
            plan_id: onboardingStore.selectedPlanId,
            abandoned_cart: onboardingStore.abandonedCartId,
            include: [
              "categoryDisplayPrice",
              "primaryCategory",
              "primaryComposableCategory",
            ],
          },
        });

      this.SET_MENU_ITEMS(res.data.data);
    },
    selectMenuItem(slug: string) {
      const found = this.menuItems.find((item) => item.slug === slug);
      if (found) {
        this.SET_SELECTED_MENU_ITEM(found);
      }
    },
  },
});
