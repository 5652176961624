import get from "lodash/get";
import type { AbandonedCartInterface , CartInterface, CartItemInterface } from "~/types/cart";

import type { RefereeInterface } from "~/types/user";
import type {
  ProductCategoryInterface,
  ProductPriceInterface,
} from "~/types/product";
import { mapPromoCode } from "./promo-code";
import { makeProductImage } from "./products";

export const parseAbandonedCart = (abandoned: {
  id: number;
  email: string;
  plan_id: number;
  filters: object;
  cart: { data: any };
  profile: { data: { id: number; id_me_profile: { data: Object } } };
  partnership: { data: object | null };
  referrer: object | null;
  referee: { data: RefereeInterface } | null;
  first_name: string;
  last_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zip_code_id: string;
  state_id: string;
  phone: string;
  delivery_instructions: string;
  address_type: string;
  referral_code: string;
  referral_code_status: string;
  price_variance_group_id: number;
  is_ready_to_checkout: boolean;
  is_ready_to_complete_checkout: boolean;
  on_referral_redemption_limit: boolean;
  receives_sms_updates: boolean;
}): AbandonedCartInterface => ({
  abandonedCartId: abandoned.id,
  email: abandoned.email,
  profileId: abandoned.profile.data.id,
  planId: abandoned.plan_id,
  filters: {
    indicators: get(abandoned.filters, "indicators", []),
    tags: get(abandoned.filters, "tags", []),
  },
  referralCode: abandoned.referral_code,
  referralCodeStatus: abandoned.referral_code_status,
  referrer: {
    firstName: get(abandoned.referrer, "first_name", ""),
    lastName: get(abandoned.referrer, "last_name", ""),
  },
  referee: {
    firstName: get(abandoned.referee?.data, "first_name", ""),
    lastName: get(abandoned.referee?.data, "last_name", ""),
    email: get(abandoned.referee?.data, "email", ""),
  },
  firstName: abandoned.first_name,
  lastName: abandoned.last_name,
  addressLine1: abandoned.address_line_1,
  addressLine2: abandoned.address_line_2,
  city: abandoned.city,
  phone: abandoned.phone,
  stateId: abandoned.state_id,
  zipCodeId: abandoned.zip_code_id,
  deliveryInstructions: abandoned.delivery_instructions,
  cart: parseCart(abandoned.cart.data),
  partnership: {
    code: get(abandoned.partnership.data, "code", null),
    description: get(abandoned.partnership.data, "description", null),
    maxOrderValue: get(abandoned.partnership.data, "max_order_value", null),
  },
  priceVarianceGroupId: abandoned.price_variance_group_id,
  isReadyToCheckout: abandoned.is_ready_to_checkout,
  isReadyToCompleteCheckout: abandoned.is_ready_to_complete_checkout,
  onReferralRedemptionLimit: abandoned.on_referral_redemption_limit,
  receivesSmsUpdates: abandoned.receives_sms_updates,
  password: null,
});

export const parseCart = (cart: {
  id: number;
  items: { data: Array<Object> };
  promoCode: any;
  costs: {
    cart_items: number;
    order_value_discount: number;
    promo_code_cost: number;
    gift_cards_amount_applied: number;
    gift_cards_amount_available: number;
    comped_amount_applied: number;
    referral_credits_amount_applied: number;
    shipping_cost: number;
    shipping_cost_before_discount: number;
    tax_cost: number;
    tax_cost_before_discount: number;
    credits_applied_amount: number;
    total_cost: number;
  };
}): CartInterface => ({
  id: cart.id,
  items: cart.items.data.map(
    (item: any): CartItemInterface => makeCartItem(item)
  ),
  promoCode: cart.promoCode ? mapPromoCode(cart.promoCode.data) : null,
  cost: {
    items: cart.costs.cart_items,
    orderValueDiscount: cart.costs.order_value_discount,
    discount: cart.costs.promo_code_cost,
    gift: cart.costs.gift_cards_amount_applied,
    availableGift: cart.costs.gift_cards_amount_available,
    discountDescription: cart.promoCode ? cart.promoCode.description : "",
    compedAmountApplied: cart.costs.comped_amount_applied,
    referralCredits: cart.costs.referral_credits_amount_applied,
    shipping: cart.costs.shipping_cost,
    shippingBeforeDiscount: cart.costs.shipping_cost_before_discount,
    tax: cart.costs.tax_cost,
    taxBeforeDiscount: cart.costs.tax_cost_before_discount,
    credits: cart.costs.credits_applied_amount,
    total: cart.costs.total_cost,
  },
});

export const makeCartItem = (item: {
  sku: string;
  slug: string;
  description: string;
  excerpt: string;
  fiber_milligrams: number;
  superfoods_number: number;
  image: any;
  category: { data: any };
  price: { data: any };
  ingredients: string;
  is_active: boolean;
  is_composite: boolean;
  is_flavor_explorer: boolean;
  is_hidden_veggie: boolean;
  veggie_milliservings: number;
  protein_milligrams: number;
  is_premium: boolean;
  item_price: number | null;
  nutrition_image: string;
  packing_name: string;
  rating: number;
  package: string;
  name: string;
  type: string | null;
  weight: number | null;
  with: string;
  total_cost: number;
  total_cost_before_discount: number;
  quantity: number;
}): CartItemInterface => ({
  sku: item.sku,
  slug: item.slug,
  description: item.description,
  excerpt: item.excerpt,
  fiberMilligrams: item.fiber_milligrams,
  superfoodsNumber: item.superfoods_number,

  image: makeProductImage(item.image),
  category: makeCategory(item.category.data),
  price: makePrice(item.price.data),

  ingredients: item.ingredients,
  isActive: item.is_active,
  isComposite: item.is_composite,
  isFlavorExplorer: item.is_flavor_explorer,
  isHiddenVeggie: item.is_hidden_veggie,

  veggieMilliservings: item.veggie_milliservings,
  proteinMilligrams: item.protein_milligrams,
  isPremium: item.is_premium,
  itemPrice: item.item_price,
  nutritionImage: item.nutrition_image,
  package: item.package,
  packingName: item.packing_name,
  rating: item.rating,
  type: item.type,
  weight: item.weight,
  with: item.with,
  name: item.name,
  totalCost: item.total_cost,
  totalCostBeforeDiscount: item.total_cost_before_discount,
  quantity: item.quantity,
});

export const makeCategory = (category: {
  id: number;
  name: string;
  slug: string;
  description: string;
  price: { data: any };
}): ProductCategoryInterface => ({
  id: category.id,
  name: category.name,
  slug: category.slug,
  description: category.description,
  price: makePrice(category.price.data),
});

export const makePrice = (price: {
  price: number;
  regular_price: number;
  original_price: number;
  original_regular_price: number;
  premium_difference: number;
  original_premium_difference: number;
}): ProductPriceInterface => ({
  price: price.price,
  regularPrice: price.regular_price,
  originalPrice: price.original_price,
  originalRegularPrice: price.original_regular_price,
  premiumDifference: price.premium_difference,
  originalPremiumDifference: price.original_premium_difference,
});
