import {
  URL_FILTERS_DIETARY_PREFERENCES,
  URL_FILTERS_INDICATORS,
  URL_FILTERS_MENU,
  URL_MENU_ITEMS,
} from "@/constants/urls";
import type { MenuItemInterface } from "~/types/menu";
import { request } from "../request";

export const getNavItems = async (promoCode = null) => {
  const {
    data: { data: navItems },
  } = await request.get(URL_FILTERS_MENU, { params: { promoCode } });
  return navItems;
};

export const getMenuItems = async () => {
  const {
    data: { data: navItems },
  } = await request.get(URL_MENU_ITEMS);
  return navItems;
};

export const getLeastExpensiveProduct = async ({
  promoCode,
  abandonedCartId,
}: {
  promoCode: string | null;
  abandonedCartId: string | null;
}) => {
  const {
    data: { data },
  } = await request.get("/api/least-expensive-product", {
    params: {
      abandoned_cart: abandonedCartId,
      promoCode,
      include: ["category"],
    },
  });
  return data;
};

export const getMenuItemStock = async (params: {
  menuItem: MenuItemInterface;
  indicators: number[];
  tags: number[];
  promoCode: string | null;
  abandonedCartId: number | null;
  page: number;
  perPage?: number;
}) =>
  request.get("/api/products", {
    params: {
      menu_item: params.menuItem.id,
      abandoned_cart: params.abandonedCartId,
      page: params.page,
      indicators: params.indicators,
      tags: params.tags,
      promoCode: params.promoCode,
      include: "badge,category",
      perPage: params.perPage ?? 12,
    },
  });

export const getIndicators = () => request.get(URL_FILTERS_INDICATORS);

export const getDietaryPreferences = () =>
  request.get(URL_FILTERS_DIETARY_PREFERENCES);

export const getHomepageBestsellers = async ({
  onlySkus,
  abandonedCartId,
}: {
  onlySkus: string[];
  abandonedCartId: number;
}) =>
  request.get("/api/products", {
    params: {
      only_skus: onlySkus,
      abandoned_cart: abandonedCartId,
    },
  });
