import eventsManager from "@/services/events/eventsManager";
import { handle as makeTrackedCart } from "~/services/events/track/cart/factories/trackedCartFromCartFactory";
import type { CartInterface } from "@/types/cart";

const track = async (cart: CartInterface) => {
  await eventsManager.push("cartUpdated", {
    env: import.meta.env.VITE_NODE_ENV,
    cart: makeTrackedCart(cart),
  });
};

export { track };
