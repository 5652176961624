import { GET_APP_SETTINGS, SET_APP_SETTINGS } from "@/stores/actions";
import { getAppSettings } from "@/services/api/appSettings";
import type { AppSetting, AppSettingsState, ReferralSystemDiscounts } from "~/types/appSettings";

export const useAppSettingsStore = defineStore("appSettings", {
  state: (): AppSettingsState => ({
    items: [],
  }),
  getters: {
    all: (state: AppSettingsState): AppSetting[] => state.items,
    byGroup: (state: AppSettingsState) => (groupName: string): AppSetting | undefined =>
      state.items.find((settings) => settings.group === groupName),
    referralSystemDiscounts(): ReferralSystemDiscounts | undefined {
      return this?.byGroup("referral_system_discounts")?.values as ReferralSystemDiscounts;
    },
  },
  actions: {
    async [GET_APP_SETTINGS]() {
      const appSettings = await getAppSettings();
      if (appSettings) {
        this[SET_APP_SETTINGS](appSettings);
      }
    },
    [SET_APP_SETTINGS](settings: AppSetting[]) {
      this.items = [...settings];
    },
  },
});
