<script setup lang="ts"></script>
<template>
  <svg
    width="60"
    height="7"
    viewBox="0 0 60 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 3.13364C13 -0.499936 20.5371 0.53743 30.5 3.13364C41.5 6.0001 47.5 6.50006 59 3.13364"
      stroke="#004B2C"
      stroke-opacity="0.2"
      stroke-width="1.5"
    />
  </svg>
</template>
