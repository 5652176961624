import DomainEvent from "./DomainEvent";

export default class PickyEaterPopupCTAClicked extends DomainEvent {
  get name() {
    return "picky-eaters-popup-cta-clicked";
  }

  get url() {
    return "/api/marketing/events/picky-eaters-popup-cta-clicked";
  }
}
