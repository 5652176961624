import get from "lodash/get";

import type {
  MealComponent,
  PriceObject,
  ProductImageInterface,
  RatingAttribute,
  ProductInterface } from "~/types/product";
import { mapCategory } from "./category";

export const mapProduct = (
  product: any,
  recursive: boolean = false
): ProductInterface => ({
  sku: product.sku,
  is_active: product.is_active,
  is_premium: product.is_premium,
  is_sold_out: product.is_sold_out,
  slug: product.slug,
  name: product.name,
  with: product.with,
  packing_name: product.packing_name,
  description: product.description,
  excerpt: product.excerpt,
  ingredients: product.ingredients,
  mealComponents: get(product, "mealComponents", []).map(mapMealComponent),
  ratingAttributes: get(product, "ratingAttributes", []).map(
    mapRatingAttribute
  ),
  show_facility_statement: product.show_facility_statement,
  package: product.package,
  rating: product.rating,
  weight: product.weight,
  superfoods_number: product.superfoods_number,
  fiber_milligrams: product.fiber_milligrams,
  protein_milligrams: product.protein_milligrams,
  veggie_milliservings: product.veggie_milliservings,
  is_hidden_veggie: product.is_hidden_veggie,
  is_flavor_explorer: product.is_flavor_explorer,
  item_price: product.item_price,
  is_composite: product.is_composite,
  type: product.type,
  after_cardinal_active_order: product.after_cardinal_active_order,
  index_number: product.index_number,
  nutritionImage: product.nutritionImage,
  price: get(product, "price.data.price", 0),
  image: makeProductImage(product.image),
  images: get(product, "images.data", []).map(makeProductImageFromUri),
  allergens: get(product, "allergens.data", []),
  facilityAllergens: get(product, "facilityAllergens.data", []),
  indicators: get(product, "indicators.data", []),
  category: product?.category ? mapCategory(product.category.data) : undefined,
  badge: get(product, "badge.data", []),
  relatedProducts: get(product, "relatedProducts.data", []).map(mapProduct),
  customersAlsoLovedProducts: mapAlsoLovedProducts(product, recursive),
  heatingInstructions: get(product, "heatingInstructions.data", []),
  icons: get(product, "icons.data", []),
  price_object: mapProductPrice(product.price.data),
  price_before_discount: get(product, "price.data.original_price", 0),
  number_of_items_per_pack: product.number_of_items_per_pack,
  price_premium_difference: get(product, "price.data.premium_difference", 0),
});

const mapMealComponent = (mealComponent: any): MealComponent => ({
  id: mealComponent.id,
  name: mealComponent.name,
  created_at: mealComponent.created_at,
  updated_at: mealComponent.updated_at,
  pivot: mealComponent.pivot,
});

const mapRatingAttribute = (ratingAttribute: any): RatingAttribute => ({
  id: ratingAttribute.id,
  name: ratingAttribute.name,
  created_at: ratingAttribute.created_at,
  updated_at: ratingAttribute.updated_at,
  pivot: ratingAttribute.pivot,
});

export const makeProductImage = (image: any): ProductImageInterface => ({
  cartDesktop: image["cart-desktop"],
  cartMobile: image["cart-mobile"],
  landingDesktop: image["landing-desktop"],
  mealCardDesktop: image["meal-card-desktop"],
  mealCardMobile: image["meal-card-mobile"],
});

const mapProductPrice = (data: any): PriceObject => ({
  price: data.price,
  regular_price: data.regular_price,
  premium_difference: data.premium_difference,
  original_price: data.original_price,
  original_regular_price: data.original_regular_price,
  original_premium_difference: data.original_premium_difference,
  original_premium_difference_per_serving:
    data.original_premium_difference_per_serving,
  original_price_per_serving: data.original_price_per_serving,
  original_regular_price_per_serving: data.original_regular_price_per_serving,
  premium_difference_per_serving: data.premium_difference_per_serving,
  price_per_serving: data.price_per_serving,
  regular_price_per_serving: data.regular_price_per_serving,
});

const makeProductImageFromUri = (image: any): ProductImageInterface => makeProductImage(image.uris);

const mapAlsoLovedProducts = (
  product: any,
  recursive: boolean
): ProductInterface[] => {
  if (!recursive) {
    return [];
  }

  const products: ProductInterface[] = [];

  if (product?.customersAlsoLovedProducts?.data) {
    product.customersAlsoLovedProducts.data.forEach((item: any) => {
      products.push(mapProduct(item, false));
    });
  }

  return products;
};
