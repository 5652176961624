import eventsManager from "@/services/events/eventsManager";
import type { AbandonedCartInterface } from "@/types/cart";
import { transform } from "./factories/abandonedCartToTrackableStateTransformer";

const track = async (abandonedCart: AbandonedCartInterface) => {
  await eventsManager.push("initializedAbandonedCart", {
    env: import.meta.env.VITE_NODE_ENV,
    ...transform(abandonedCart),
  });
};

export { track };
