import {
  URL_ABANDONED_CART_PLANS,
  URL_BESTSELLER_GROUPS,
  URL_CATEGORIES,
  URL_FILL_WITH_BESTSELLERS,
  URL_PLANS,
  URL_CATEGORY_QUANTITIES,
  BEAM_NON_PROFIT,
} from "@/constants/urls";
import { request } from "../request";

export const getPlans = (promoCode: string | undefined, abandonedCartId: number | null) => {
  if (!abandonedCartId) {
    return request.get(URL_PLANS, { params: { promoCode } });
  }
  return request.get(
    URL_ABANDONED_CART_PLANS.replace("{{abandonedCartId}}", abandonedCartId.toString()),
    { params: { promoCode } }
  );
};

export const fetchBestsellerGroups = (params = {}) =>
  request.get(URL_BESTSELLER_GROUPS, { params });

export const fetchCategories = (params = {}) =>
  request.get(URL_CATEGORIES, { params });

export const fillCartWithBestsellers = (
  abandonedCartId: number | null,
  bestsellerGroupId: number,
  sourceUrl: string,
) =>
  request.post(
    URL_FILL_WITH_BESTSELLERS.replace(
      "{{abandonedCartId}}",
      abandonedCartId?.toString() || ""
    ).replace("{{bestsellerGroupId}}", bestsellerGroupId.toString()),
    { source_url: sourceUrl }
  );
export const updateBeamNonProfit = (
  nonProfitId: number,
  nonProfitName: string,
  abandonedCartId: number | null,
) =>
  request.post(BEAM_NON_PROFIT, {
    non_profit_id: nonProfitId,
    non_profit_name: nonProfitName,
    abandoned_cart_id: abandonedCartId,
  });

export const getCategoryQuantities = () => request.get(URL_CATEGORY_QUANTITIES);
