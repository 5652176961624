import { sha1 } from "js-sha1";
import eventsManager from "@/services/events/eventsManager";

export const track = async (
  email: string,
  isLoggedIn: boolean,
  eventId?: string
) => {
  const onboardingStore = useOnboardingStore();
  const authStore = useAuthStore();

  const params = new URLSearchParams(window.location.search);
  const emailGetParam = params.get("email");
  if (emailGetParam) {
    return;
  }

  await eventsManager.push("userDiscovered", {
    conversion: {
      eventId,
    },
    email,
    shaEmail: email ? sha1(email) : null,
    login: isLoggedIn,
    abandonedCartId: onboardingStore?.abandonedCartId,
    userId: authStore?.userId,
  });
};
