import { reactive, computed } from "vue";

export interface UseFocusOptions {
  initialFields?: Record<string, boolean>
}

export function useFocus(options: UseFocusOptions = {}) {
  const { initialFields = {} } = options;
  
  const focus = reactive<Record<string, boolean>>(initialFields);
  
  const focusField = (field: string) => {
    focus[field] = true;
  };
  
  const blurField = (field: string) => {
    focus[field] = false;
  };
  
  const hasFocus = (field: string) => focus[field] === true;

  const clearFocusState = () => {
    Object.keys(focus).forEach((field) => {
      focus[field] = false;
    });
  };
  
  const focusedFields = computed(() => Object.entries(focus)
    .filter(([_, isFocused]) => isFocused)
    .map(([field]) => field));
  
  return {
    focus,
    focusField,
    blurField,
    hasFocus,
    focusedFields,
    clearFocusState,
  };
}