import { defineNuxtRouteMiddleware, navigateTo } from "nuxt/app";
import { URL_ACCOUNT } from "~/constants/urls";

export default defineNuxtRouteMiddleware((to, ) => {
  const onboardingStore = useOnboardingStore();
  const cartStore = useCartStore();

  switch (true) {
    case to.path.includes("/onboarding/forgot-password"):
      if (onboardingStore.isForgotPasswordAvailable) {
        return;
      }

      navigateTo(URL_ACCOUNT, {
        external: true,
      });
      break;

    case to.path.includes("/menus"):
      if (typeof window !== "undefined" && window.innerWidth >= 1024) {
        cartStore.OPEN_CART();
      }
      break;

    default:
  }
});
