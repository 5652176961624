import { URL_EVENT_VIEWED_PAGE } from "@/constants/urls";
import DomainEvent from "./DomainEvent";

export default class PageViewed extends DomainEvent {
  get name() {
    return "viewed-page";
  }

  get url() {
    return URL_EVENT_VIEWED_PAGE;
  }
}
