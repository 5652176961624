export const URL_API_BASE = import.meta.env.VITE_BASE_API_ENDPOINT;
export const URL_ACCOUNT = import.meta.env.VITE_BASE_ACCOUNT_URL;
export const URL_REACTIVATE_PAGE = `${
  import.meta.env.VITE_BASE_ACCOUNT_URL
}/reactivate`;
export const URL_ACCOUNT_ORDERS_PAGE = `${
  import.meta.env.VITE_BASE_ACCOUNT_URL
}/orders`;
export const DOMAIN = import.meta.env.VITE_DOMAIN;
export const URL_MARKETING = `https://${DOMAIN}`;
export const URL_MENUS_PAGE = `${URL_MARKETING}/menus`;
// Onboarding
export const URL_AGE_GROUPS = "/api/customers/age-groups";
export const URL_CATEGORIES = "/api/categories";
export const URL_ABANDONED_CART_PLANS =
  "/api/sales/abandoned-carts/{{abandonedCartId}}/plans";
export const URL_PLANS = "/api/sales/plans";
export const URL_CATEGORY_QUANTITIES = "/api/me/category-quantities";
export const URL_BESTSELLER_GROUPS = "/api/bestsellers";
export const URL_FILL_WITH_BESTSELLERS =
  "/api/customers/abandoned-carts/{{abandonedCartId}}/filled-with-bestsellers/{{bestsellerGroupId}}";
// Cart
export const URL_CREATE_CART = "/api/sales/carts";
export const URL_ABANDONED_CARTS = "/api/customers/abandoned-carts";
export const URL_ABANDONED_CART_ITEMS =
  "/api/sales/abandoned-carts/{{abandonedCartId}}/items";
export const URL_ABANDONED_CART = "/api/customers/abandoned-carts/{{cartId}}";
export const URL_ABANDONED_CART_PROMO =
  "/api/customers/abandoned-carts/{{cartId}}/promo-codes";
export const URL_ABANDONED_CART_REFERRAL =
  "/api/customers/abandoned-carts/{{cartId}}/referral-codes";
export const URL_ABANDONED_CART_FILTERS =
  "/api/customers/abandoned-carts/{{cartId}}/filters";
export const URL_UPDATE_ABANDONED_CART_PLAN_ID =
  "/api/sales/abandoned-carts/{{abandonedCartId}}/plans";
export const URL_KIDS =
  "/api/customers/abandoned-carts/{{cartId}}/profile/kids";
export const URL_KID =
  "/api/customers/abandoned-carts/{{cartId}}/profile/kids/{{kidId}}";
export const URL_KID_CATEGORIES =
  "/api/customers/abandoned-carts/{{cartId}}/profile/preferred-categories";
export const URL_KID_TAGS_AND_INDICATORS =
  "/api/customers/abandoned-carts/{{cartId}}/profile/preferred-categories-filters";
export const URL_PROMO_CODE_BANNER = "/wp-admin/admin-ajax.php";
// Orders
export const URL_ORDERS = "/api/sales/users/{{userId}}/orders";
export const URL_PROMO_CODE = "/api/me/promo-code";
// Products
export const URL_FILTERS_MENU = "/api/product-filters/menu";
export const URL_FILTERS_TYPES_OF_EATER = "/api/product-filters/types-of-eater";
export const URL_FILTERS_INDICATORS = "/api/product-filters/indicators";
export const URL_FILTERS_DIETARY_PREFERENCES =
  "/api/product-filters/dietary-preferences";
export const URL_TAGS = "/api/tags";
export const URL_MENU_ITEMS = "/api/product-menu-items";
export const CUSTOMERS_ALSO_LOVED_LOG_URL = "/api/customers-also-loved-clicked";
export const URL_RETRIEVE_EXPERIMENTS = "/api/experiments";

// Auth
export const URL_LOGIN = "/auth";
export const URL_VERIFY = "/auth/verification";
export const URL_INITIAL_LOGIN = "/auth/initial";
export const URL_REQUEST_RESET_PASSWORD = "/auth/password-resets";
export const URL_RESET_PASSWORD = "/auth/completed-password-resets";
export const URL_USER = "/api/me?include=address";

// User
export const URL_DELIVERY_ADDRESS = "/api/me/addresses/{{addressId}}";

// Events
export const URL_EVENT_VIEWED_PAGE = "/api/marketing/esp/events/viewed-page";

// Gift Cards
export const URL_GIFT_CARD_PROMOTIONS = "/api/sales/gift-cards/promotions";
export const URL_GIFT_CARD_SUGGESTIONS = "/api/sales/gift-card-suggestions";
export const URL_GIFT_CARD_AMOUNT_UPDATE =
  "/api/sales/gift-cards/purchase-intent/{{payment_intent_ID}}";
export const URL_GIFT_CARDS_PURCHASE_INTENT =
  "/api/sales/gift-cards/purchase-intent";
export const URL_GIFT_CARDS_PURCHASE = "/api/sales/gift-cards/purchase";

// ZIP Codes
export const URL_ZIP_CODE = "/api/shipping/zip-codes/{{code}}";

// Social authentications
export const GOOGLE_AUTH = `${URL_API_BASE}/auth/google`;
export const FACEBOOK_AUTH = `${URL_API_BASE}/auth/facebook`;
export const APPLE_AUTH = `${URL_API_BASE}/auth/apple`;

// App settings
export const URL_APP_SETTINGS = "/api/sales/app-settings";

// Upsells
export const URL_UPSELL = "api/sales/checkout/upsell/items";
export const URL_INCREASE_UPSELL_ITEM =
  "api/sales/checkout/upsell/items/increase";
export const URL_DECREASE_UPSELL_ITEM =
  "api/sales/checkout/upsell/items/decrease";
export const BEAM_NON_PROFIT = "/api/marketing/beam/nonprofit";

// Checkout
export const URL_STATES = "/api/shipping/states";
export const URL_DELIVERY_QUOTES = "/api/sales/delivery-quotes";
export const URL_CHECKOUT =
  "/api/sales/abandoned-carts/{{abandonedCartId}}/purchase";
export const URI_STORE_ABANDONED_CART_PURCHASE_INTENT =
  "/api/billing/abandoned-carts/{{abandonedCartId}}/purchase-intent";
export const URL_STRIPE_BILLING_TOKEN = "https://api.stripe.com/v1/tokens";
