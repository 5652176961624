import events from "./Events";

export default class DomainEvents {
  push(event: string, params = {}) {
    this.find(event)?.fire(params);
  }

  find(eventName: string) {
    return events.find((event) => event.name === eventName);
  }
}
