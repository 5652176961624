import type { Category, CategoryPrice } from "~/types/product";

export const mapCategory = (category: any): Category => ({
  id: category.id,
  slug: category.slug,
  name: category.name,
  description: category.description,
  sorting_weight: category.sorting_weight,
  composable_name: category.composable_name,
  composable_name_plural: category.composable_name_plural,
  composable_package_name: category.composable_package_name,
  composable_package_name_plural: category.composable_package_name_plural,
  is_active: category.is_active,
  discount: category.discount,
  pack_of: category.pack_of,
  pricing_divisor: category.pricing_divisor,
  price: mapCategoryPrice(category.price.data),
  pricing_divisor_label: category.pricing_divisor_label,
});

const mapCategoryPrice = (data: any): CategoryPrice => ({
  price: data.price,
  premium_price: data.premium_price,
  premium_difference: data.premium_difference,
  price_per_serving: data.price_per_serving,
  regular_price_per_serving: data.regular_price_per_serving,
  premium_difference_per_serving: data.premium_difference_per_serving,
  original_price_in_cents: data.original_price_in_cents,
  original_premium_price_in_cents: data.original_premium_price_in_cents,
  original_premium_difference_in_cents:
    data.original_premium_difference_in_cents,
  original_price: data.original_price,
  original_premium_price: data.original_premium_price,
  original_premium_difference: data.original_premium_difference,
  display_price: data.display_price,
  display_price_in_cents: data.display_price_in_cents,
  original_display_price: data.original_display_price,
  original_display_price_in_cents: data.original_display_price_in_cents,
});
