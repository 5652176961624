import get from "lodash/get";
import size from "lodash/size";
import type { LoginErrorResponse, LoginSuccessResponse } from "~/types/auth";
import { AxiosError } from "axios";
import { request } from "../request";
import ValidationError from "../../models/ValidationError";
import {
  URL_LOGIN,
  URL_INITIAL_LOGIN,
  URL_RESET_PASSWORD,
  URL_REQUEST_RESET_PASSWORD,
  URL_USER,
  URL_VERIFY,
} from "../../constants/urls";

export const login = async (
  email: string,
  password: string
): Promise<LoginSuccessResponse | LoginErrorResponse> => {
  try {
    const response = await request.post<LoginSuccessResponse>(URL_LOGIN, {
      email,
      password,
    });
    return response.data;
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      if (e.response?.data && e.response.data.errors) {
        e.response.data.error =
          "The email and password combination is incorrect.";
      }

      if (e.response?.status === 429) {
        e.response.data.error =
          "Request limit exceeded. Please try again in a minute.";
      }

      return e.response?.data as LoginErrorResponse;
    }

    // Handle non-axios errors by throwing them
    throw e;
  }
};

export const verify = async (code: string, verificationId: string) => {
  try {
    return await request.post(URL_VERIFY, { code, verificationId });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      return e.response?.data;
    }
  }
  return null;
};

export const loginInitially = async (token: string) => {
  await request.post(URL_INITIAL_LOGIN, { token });
};

export const logout = () => request.delete(URL_LOGIN);

export const requestResetPassword = async (email: string) => {
  try {
    await request.post(URL_REQUEST_RESET_PASSWORD, { email });
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      const errors = get(e.response, "data", {});
      throw size(errors) ? new ValidationError(errors) : e;
    }
    throw e;
  }
};

export const resetPassword = async (
  email: string,
  password: string,
  token: string
) => {
  try {
    const {
      data: { access_token: accessToken, expires_in: expiresIn },
    } = await request.post(URL_RESET_PASSWORD, {
      email,
      password,
      password_confirmation: password,
      token,
    });
    return { accessToken, expiresIn };
  } catch (e: unknown) {
    if (e instanceof AxiosError) {
      const errors = get(e.response, "data.errors", {});
      throw size(errors) ? new ValidationError(errors) : e;
    }
    throw e;
  }
};

export const getUser = async () => {
  let response;

  try {
    response = await request.get(URL_USER);
  } catch (exception) {
    return null;
  }

  const user = response.data.data;

  return {
    id: user.id,
    email: user.email,
    referralId: user.referralId ? user.referralId : user.referral_id,
    profile: user.profile ? user.profile.data : null,
    subscription: user.subscription ? user.subscription.data : null,
    address: user.address ? user.address.data : null,
  };
};
