import Flash from "../Flash";

export default class FlashableError extends Error {
  errorMessage: string;

  constructor(error: string) {
    super(error);
    this.errorMessage = error;
  }

  dispatchFlashNotification() {
    new Flash(this.errorMessage).error();
  }
}
