import { request } from "../../services/request";

export default class DomainEvent {
  get url() {
    return "";
  }

  fire(params: any) {
    request.post(this.url, params);
  }
}
