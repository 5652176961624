// To check viewport states and conditional rendering to use this one composable

import type { ToRefs } from "vue";

interface ScreenQuery {
  desktop: string;
  mobile: string;
  tablet: string;
}
interface ScreenList {
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
}
const screens = reactive<ScreenList>({
  isDesktop: false,
  isMobile: false,
  isTablet: false,
});
const screenWidth = ref(0);

export function useScreen(query?: ScreenQuery): ToRefs<ScreenList> & {
  screenWidth: Ref<number>;
} {
  function onWindowResize(): void {
    screenWidth.value = window.innerWidth;
  }

  if (query && process.client) {
    onMounted(() => {
      const createListener = (screen: keyof ScreenList) => (event: MediaQueryList | MediaQueryListEvent) => {
        if (event.matches) {
          screens[screen] = true;
        } else {
          screens[screen] = false;
        }
      };
      const onChangeDesktop = createListener("isDesktop");
      const onChangeTablet = createListener("isTablet");
      const onChangeMobile = createListener("isMobile");
      const mqlDesktop = window.matchMedia(query.desktop);
      const mqlTablet = window.matchMedia(query.tablet);
      const mqlMobile = window.matchMedia(query.mobile);
      mqlDesktop.addEventListener("change", onChangeDesktop);
      onChangeDesktop(mqlDesktop);
      mqlTablet.addEventListener("change", onChangeTablet);
      onChangeTablet(mqlTablet);
      mqlMobile.addEventListener("change", onChangeMobile);
      onChangeMobile(mqlMobile);

      window.addEventListener("resize", onWindowResize);
      onWindowResize();
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", onWindowResize);
    });
  }

  return {
    ...toRefs(screens),
    screenWidth,
  };
}
