import axios, { type AxiosInstance, type AxiosRequestConfig } from "axios";
import { URL_API_BASE } from "../constants/urls";
import ValidationError from "../models/ValidationError";

// Request manager to handle all cancellations
class RequestManager {
  private pendingRequests: Map<string, AbortController>;

  constructor() {
    this.pendingRequests = new Map();
  }

  // Generate a unique key for each request
  private static getRequestKey(config: AxiosRequestConfig): string {
    return `${config.method}-${config.url}-${JSON.stringify(config.params || {})}`;
  }

  // Add a request to tracking
  public addRequest(config: AxiosRequestConfig): void {
    const key = RequestManager.getRequestKey(config);
    this.cancelRequest(key); // Cancel any existing request with same key

    const abortController = new AbortController();
    config.signal = abortController.signal;
    this.pendingRequests.set(key, abortController);
  }

  // Remove a request from tracking
  public removeRequest(config: AxiosRequestConfig): void {
    const key = RequestManager.getRequestKey(config);
    this.pendingRequests.delete(key);
  }

  // Cancel a specific request
  public cancelRequest(key: string): void {
    const abortController = this.pendingRequests.get(key);
    if (abortController) {
      abortController.abort();
      this.pendingRequests.delete(key);
    }
  }

  // Cancel all pending requests
  public cancelAll(): void {
    this.pendingRequests.forEach((abortController) => abortController.abort());
    this.pendingRequests.clear();
  }
}

// Create a single instance
const requestManager = new RequestManager();

// Create the axios instance
export const request: AxiosInstance = axios.create({
  baseURL: URL_API_BASE,
  withCredentials: true,
  timeout: 30000,
});

const isServer = process.server;

request.interceptors.request.use(
  (config) => {
    // Only use request manager on client side
    if (!isServer) {
      requestManager.addRequest(config);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

request.interceptors.response.use(
  (response) => {
    if (!isServer) {
      requestManager.removeRequest(response.config);
    }
    return response;
  },
  (error) => {
    // Handle cleanup
    if (!isServer && error.config) {
      requestManager.removeRequest(error.config);
    }

    // Handle cancellation
    if (axios.isCancel(error)) {
      return Promise.reject(); // Return empty reject for cancellations
    }

    // Handle validation errors
    if (error.response?.status === 422) {
      const { errors } = error.response.data;
      if (errors) {
        return Promise.reject(new ValidationError(errors));
      }
      return Promise.reject(
        new Error(error.response.data.message || "Validation error occurred")
      );
    }

    // Handle other errors
    return Promise.reject(error);
  }
);

export { requestManager };

export const directRequest = axios.create();

export function controller(): AbortController {
  return new AbortController();
}

export default request;
