import type { PromoCodeInterface } from "@/types/promoCode";

export const mapPromoCode = (promoCode: any): PromoCodeInterface => ({
  id: promoCode.id,
  code: promoCode.code,
  cartAmountMinimum: promoCode.cart_amount_minimum,
  cartAmountMaximum: promoCode.cart_amount_maximum,
  aggregateDollarsOffAmount: promoCode.aggregate_dollars_off_amount,
  description: promoCode.description,
  descriptionCheckout: promoCode.description_checkout,
  showBanner: promoCode.show_banner ?? false,
  showBannerCheckout: promoCode.show_banner_checkout ?? false,
  tiers: promoCode?.regulations?.data || [],
});
