import type { AppSetting, AppSettingsResponse } from "~/types/appSettings";
import { URL_APP_SETTINGS } from "../../constants/urls";
import { request } from "../request";

export const getAppSettings = async (): Promise<AppSetting[] | null> => {
  try {
    const response = await request.get<AppSettingsResponse>(URL_APP_SETTINGS);
    return response.data.data;
  } catch (e) {
    return null;
  }
};