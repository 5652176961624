// Base implementation for onboarding store

import orderBy from "lodash/orderBy";
import get from "lodash/get";
import first from "lodash/first";
import FlashableError from "@/models/Exceptions/FlashableError";
import { AxiosError } from "axios";

import {
  getAbandonedCart,
  createAbandonedCart,
  updateAbandonedCart,
  updateAbandonedCartFilters,
  updatePlan,
} from "@/services/api/abandoned-cart";
import {
  saveAbandonedCartId,
  restoreAbandonedCartId,
  removeAbandonedCartId,
} from "@/services/storage";

import { track as trackAbandonedCartInitialized } from "@/services/events/track/cart/initializedAbandonedCart";
import { track as trackAbandonedCartUpdated } from "@/services/events/track/cart/updatedAbandonedCart";
import AbandonedCart from "@/models/AbandonedCart";
import type { ReferrerInterface, RefereeInterface } from "@/types/user";

import { track as trackPlanChanged } from "@/services/events/track/cart/planChanged";
import {
  fetchBestsellerGroups,
  fillCartWithBestsellers,
  getPlans,
  getCategoryQuantities,
} from "~/services/api/onboarding";
import { removeTrailingSlashes } from "~/utils/helpers";
import type { CategoryQuantity } from "~/types/category";
import type { Plan } from "~/types/plan";
import type { BestsellerGroup } from "~/types/bestseller";

interface OnboardingStoreState {
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  stateId: string | null;
  zipCodeId: string | null;
  abandonedCartId: number | null;
  cartId: number | null;
  planId: null | number;
  profileId: string | null | number;
  plans: Plan[];
  bestsellerGroups: BestsellerGroup[];
  filters: any | null;
  isReadyToCheckout: boolean | null;
  isReadyToCompleteCheckout: boolean;
  categories: any[];
  showChangePlanPopup: boolean;
  pendingBestsellerGroupId: number | null;
  addressLine1: string | null;
  abandonedCartExist: boolean;
  addressLine2: string | null;
  city: string | null;
  phone: string | null;
  deliveryInstructions: string | null;
  onReferralRedemptionLimit: boolean | null;
  receivesSmsUpdates: boolean | null;
  priceVarianceGroupId: null | number;
  referee: RefereeInterface | null;
  referrer: ReferrerInterface | null;
  allowedRoutes: string[];
}

export const useOnboardingStore = defineStore("onboardingStore", {
  state: (): OnboardingStoreState => ({
    email: null,
    firstName: null,
    lastName: null,
    stateId: null,
    zipCodeId: null,
    abandonedCartId: null,
    abandonedCartExist: false,
    cartId: null,
    planId: null,
    profileId: null,
    plans: [],
    bestsellerGroups: [],
    filters: null,
    isReadyToCheckout: false,
    isReadyToCompleteCheckout: false,
    categories: [],
    showChangePlanPopup: false,
    pendingBestsellerGroupId: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    phone: null,
    deliveryInstructions: null,
    onReferralRedemptionLimit: false,
    receivesSmsUpdates: true,
    priceVarianceGroupId: null,
    referee: null,
    referrer: null,
    allowedRoutes: [],
  }),
  getters: {
    state: ({ stateId }) => stateId,
    abandonedCart: (state) => {
      const abandonedCart = new AbandonedCart(state);

      abandonedCart.firstName = state.firstName;
      abandonedCart.lastName = state.lastName;
      abandonedCart.stateId = state.stateId;

      return abandonedCart;
    },
    selectedPlan: (state) =>
      state.plans.find((plan) => plan.id === state.planId),
    selectedPlanId: ({ planId }) => planId,
    largestPlan: (state) =>
      state.plans.length > 0
        ? [...state.plans].sort((plan) => plan.threshold * -1)[0]
        : null,
    isLoginAvailable: () => {
      const authStore = useAuthStore();
      return !authStore.isLoggedIn;
    },
    isForgotPasswordAvailable: () => {
      const authStore = useAuthStore();
      return !authStore.isLoggedIn;
    },
    isCheckoutStepAvailable: (state) => state.isReadyToCheckout,
    bestsellerGroupsShownOnCart: (state) =>
      state.bestsellerGroups.filter((group) => group.show_on_cart),
    maxPlanThreshold: (state) =>
      get(
        first(orderBy(state.plans, ["threshold"], ["desc"])),
        "threshold",
        null
      ),
    hasPromoCode() {
      const cartStore = useCartStore();
      return (
        cartStore.promoCode &&
        cartStore.promoCode.code !== null &&
        cartStore.promoCode.code !== undefined &&
        cartStore.promoCode.code !== ""
      );
    },
    promoCodeTiers() {
      const cartStore = useCartStore();

      return cartStore?.promoCode?.tiers || [];
    },
    isPromoCodeMultiTiers() {
      return this.promoCodeTiers?.length > 1;
    },
    hasPromoCodeOrPromoCodeTiers() {
      return this.hasPromoCode || this.isPromoCodeMultiTiers;
    },
    /**
     * This is used to determine the menu sidebar position on the Y axis. Depending on whether we show a banner or
     * not we set the top attribute to different values. When no banner is shown we set it to 72px which is the
     * height of the menu bar. When we show a banner, we will sum up the header and the promo banner height.
     */
    isAnyTopBannerVisible() {
      const cartStore = useCartStore();
      const { promoCodeBanner, bannerTypeToShow, referralCodeStatus } =
        storeToRefs(cartStore);

      if (this.isPromoCodeMultiTiers) {
        return true;
      }

      if (referralCodeStatus.value) {
        return true;
      }

      if (bannerTypeToShow.value !== "discount") {
        return false;
      }

      return (
        promoCodeBanner.value &&
        promoCodeBanner.value?.description &&
        promoCodeBanner.value.showBanner
      );
    },
  },
  actions: {
    async GET_CATEGORY_QUANTITIES(): Promise<CategoryQuantity[]> {
      const response = await getCategoryQuantities();

      return response.data.data;
    },

    async GET_BESTSELLER_GROUPS({
      abandonedCartId,
    }: {
      abandonedCartId: number;
    }) {
      const response = await fetchBestsellerGroups({
        abandonedCartId,
      });

      this.UPDATE_ONBOARDING_STATE({ bestsellerGroups: response.data.data });
    },
    async [RESTORE_ABANDONED_CART]() {
      const abandonedCartId = restoreAbandonedCartId();
      if (abandonedCartId) {
        this.$state.abandonedCartId = Number(abandonedCartId);
        await this[GET_ABANDONED_CART]();
      }
    },
    async FILL_CART_WITH_BESTSELLERS({
      bestsellerGroupId,
      sourceUrl,
    }: {
      bestsellerGroupId: number;
      sourceUrl: string;
    }) {
      const menusStore = useMenusStore();
      if (!this.email || !this.zipCodeId) {
        this.UPDATE_ONBOARDING_STATE({
          pendingBestsellerGroupId: bestsellerGroupId,
        });
        menusStore.SHOW_CAPTURE_EMAIL_POPUP(true);
      }
      await fillCartWithBestsellers(
        this.abandonedCartId,
        bestsellerGroupId,
        sourceUrl
      );
      this.UPDATE_ONBOARDING_STATE({ pendingBestsellerGroupId: null });
      await this.GET_ABANDONED_CART();
    },
    async [GET_ABANDONED_CART]() {
      try {
        const abandonedCart = await getAbandonedCart(
          this.$state.abandonedCartId as number
        );

        const cartStore = useCartStore();
        cartStore[SET_CART](abandonedCart.cart);
        this[UPDATE_ONBOARDING_STATE]({
          email: abandonedCart.email,
          abandonedCartId: abandonedCart.abandonedCartId,
          planId: abandonedCart.planId,
          filters: abandonedCart.filters,
          isReadyToCheckout: abandonedCart.isReadyToCheckout,
          isReadyToCompleteCheckout: abandonedCart.isReadyToCompleteCheckout,
          profileId: abandonedCart.profileId,
          firstName: abandonedCart.firstName,
          lastName: abandonedCart.lastName,
          addressLine1: abandonedCart.addressLine1,
          addressLine2: abandonedCart.addressLine2,
          city: abandonedCart.city,
          stateId: abandonedCart.stateId,
          zipCodeId: abandonedCart.zipCodeId,
          phone: abandonedCart.phone,
          deliveryInstructions: abandonedCart.deliveryInstructions,
          priceVarianceGroupId: abandonedCart.priceVarianceGroupId,
          onReferralRedemptionLimit: abandonedCart.onReferralRedemptionLimit,
          receivesSmsUpdates: abandonedCart.receivesSmsUpdates,
          referee: abandonedCart.referee,
          referrer: abandonedCart.referrer,
        });
        cartStore[UPDATE_CART_STATE]({
          referrer: abandonedCart.referrer,
          referralCode: abandonedCart.referralCode,
          referralCodeStatus: abandonedCart.referralCodeStatus,
          referee: abandonedCart.referee,
        });

        if (abandonedCart?.cart?.promoCode) {
          cartStore[SET_PROMO_CODE_BANNER](abandonedCart?.cart?.promoCode);
        }

        trackAbandonedCartInitialized(abandonedCart);
        this.abandonedCartExist = true;
      } catch (e) {
        removeAbandonedCartId();
        this[UPDATE_ONBOARDING_STATE]({ abandonedCartId: null });
      }
    },
    async [UPDATE_ABANDONED_CART](data: any) {
      const cartStore = useCartStore();

      if (!this.abandonedCartId) {
        await this[CREATE_ABANDONED_CART]();
      }
      try {
        const { abandonedCart } = data;
        abandonedCart.email = abandonedCart.email
          ? abandonedCart.email
          : this.email;
        const updatedAbandonedCart = await updateAbandonedCart(
          Number(this.abandonedCartId),
          abandonedCart,
          data.page
        );

        this[UPDATE_ONBOARDING_STATE]({
          email: updatedAbandonedCart.email,
          firstName: updatedAbandonedCart.firstName,
          lastName: updatedAbandonedCart.lastName,
          stateId: updatedAbandonedCart.stateId,
          zipCodeId: updatedAbandonedCart.zipCodeId,
          addressLine1: updatedAbandonedCart.addressLine1,
          addressLine2: updatedAbandonedCart.addressLine2,
          city: updatedAbandonedCart.city,
          phone: updatedAbandonedCart.phone,
          deliveryInstructions: updatedAbandonedCart.deliveryInstructions,
          receivesSmsUpdates: updatedAbandonedCart.receivesSmsUpdates,
          isReadyToCompleteCheckout:
            updatedAbandonedCart.isReadyToCompleteCheckout,
        });
        cartStore[SET_CART](updatedAbandonedCart.cart);
        trackAbandonedCartUpdated(updatedAbandonedCart);
      } catch (errors) {
        if (errors === undefined) {
          return;
        }
        const axiosError = errors as AxiosError;
        if (axiosError.response && axiosError.response.status === 403) {
          this[CLEAR_ABANDONED_CART]();
        }

        if (errors instanceof FlashableError) {
          errors.dispatchFlashNotification();
        }

        throw errors as Error;
      }
    },
    async GET_PLANS() {
      const cartStore = useCartStore();

      const response = await getPlans(
        cartStore?.promoCode?.code,
        this.abandonedCartId
      );
      this.UPDATE_ONBOARDING_STATE({ plans: response.data.data });
    },
    async UPDATE_PLAN({
      planId,
      sourceUrl,
    }: {
      planId: number | null | undefined;
      sourceUrl: string;
    }) {
      const cartStore = useCartStore();
      if (!this.abandonedCartId) {
        throw new Error("Abandoned cart id does not exist");
      }
      const { abandonedCart, eventId } = await updatePlan(
        Number(this.abandonedCartId),
        Number(planId),
        sourceUrl
      );
      this[UPDATE_ONBOARDING_STATE]({
        planId: abandonedCart.planId,
        isReadyToCheckout: abandonedCart.isReadyToCheckout,
      });
      cartStore.SET_CART(abandonedCart.cart);
      trackPlanChanged(eventId, Number(abandonedCart.planId));
    },
    async UPDATE_FILTERS({
      abandonedCartId,
      indicators,
      tags,
    }: {
      abandonedCartId: any;
      indicators: any;
      tags: any;
    }) {
      const response = await updateAbandonedCartFilters(abandonedCartId, {
        indicators,
        tags,
      });
      this[UPDATE_ONBOARDING_STATE]({ filters: response.data.data });
    },
    async [CREATE_ABANDONED_CART]() {
      const abandonedCart = await createAbandonedCart();
      const cartStore = useCartStore();

      if (abandonedCart.abandonedCartId !== null) {
        this[UPDATE_ONBOARDING_STATE]({
          abandonedCartId: abandonedCart.abandonedCartId,
          cartId: abandonedCart?.cart?.id,
        });
        cartStore[UPDATE_CART_STATE]({ id: abandonedCart?.cart?.id });
        saveAbandonedCartId(abandonedCart.abandonedCartId.toString());
        trackAbandonedCartInitialized(abandonedCart);
        this.abandonedCartExist = true;
      }
    },
    [UPDATE_ONBOARDING_STATE](stateUpdate: Partial<OnboardingStoreState>) {
      this.$patch(stateUpdate);
    },
    [CLEAR_ABANDONED_CART]() {
      removeAbandonedCartId();
      this.abandonedCartId = null;
      this.email = null;
    },
    allowRouteAccess(route: string) {
      const normalizedRoute = removeTrailingSlashes(route);
      if (!this.allowedRoutes.includes(normalizedRoute)) {
        this.allowedRoutes.push(normalizedRoute);
      }
    },
    revokeRouteAccess(route: string) {
      const normalizedRoute = removeTrailingSlashes(route);
      this.allowedRoutes = this.allowedRoutes.filter(
        (r) => r !== normalizedRoute
      );
    },
    isRouteAllowed(route: string) {
      const normalizedRoute = removeTrailingSlashes(route);
      return this.allowedRoutes.includes(normalizedRoute);
    },
  },
});
