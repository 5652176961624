import { URL_ABANDONED_CART_ITEMS } from "@/constants/urls";

import { request, controller } from "@/services/request";
import type { AbandonedCartInterface , CartItemDifferenceInterface } from "@/types/cart";
import { parseAbandonedCart } from "~/services/api/data/abandoned-cart";

function url(abandonedCartId: string): string {
  return URL_ABANDONED_CART_ITEMS.replace(
    "{{abandonedCartId}}",
    abandonedCartId
  );
}

let updateItemQuantityController: null | AbortController = null;

interface Pixel {
  id: string;
  name: string;
  params: Object;
}

export async function updateItemQuantity(
  abandonedCartId: string,
  sku: string,
  quantity: number,
  sourceUrl: string
): Promise<{
  abandonedCart: AbandonedCartInterface;
  difference: CartItemDifferenceInterface;
  pixels: Pixel[];
}> {
  if (updateItemQuantityController) {
    updateItemQuantityController.abort();
  }

  updateItemQuantityController = controller();

  const response = await request.put(
    url(abandonedCartId),
    { sku, quantity, source_url: sourceUrl },
    { signal: updateItemQuantityController.signal }
  );

  const difference = response.data.meta.difference.data;

  return {
    abandonedCart: parseAbandonedCart(response.data.data),
    difference: {
      sku: difference.sku,
      name: difference.name,
      quantity: difference.quantity,
      quantityAbsolute: difference.quantity_absolute,
      price: difference.price,
      total: difference.total,
      categoryName: difference.category_name,
    },
    pixels: response.data.meta.pixels,
  };
}

export async function removeItem(
  abandonedCartId: string,
  sku: string,
  sourceUrl: string
): Promise<{
  abandonedCart: AbandonedCartInterface;
  difference: CartItemDifferenceInterface;
  pixels: Pixel[];
}> {
  return updateItemQuantity(abandonedCartId, sku, 0, sourceUrl);
}

export async function removeAllItems(
  abandonedCartId: string
): Promise<AbandonedCartInterface> {
  const response = await request.delete(url(abandonedCartId));
  return parseAbandonedCart(response.data.data);
}
