import qs from "qs";
import trim from "lodash/trim";
import zip from "lodash/zip";
import type { ParsedQs } from "qs";
import type { BundlePreset } from "~/types/bundle";

const BUNDLE_SKUS_PARAM = "skus";
const BUNDLE_QUANTITIES_PARAM = "quantities";

export const getUrlReferralCode = (): string | null => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const referralId = params.referral_id;
  if (typeof referralId === "string") {
    return referralId;
  }
  return null;
};

export const getUrlRefereeEmail = (): string | null => {
  const params = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { email } = params;
  if (typeof email === "string") {
    return email;
  }
  return null;
};

export const getUrlBundle = (params: ParsedQs = {}) => {
  const preset: BundlePreset = {
    items: [],
    replace: false,
    getTotalQuantityAfterApplied(currentCartProductsCount: number) {
      return (
        this.items.reduce((count, item) => count + item.quantity, 0) +
        currentCartProductsCount
      );
    },
    requiresPlanUpgrade() {
      const onboardingStore = useOnboardingStore();
      const cartStore = useCartStore();

      return (
        this.getTotalQuantityAfterApplied(cartStore.cartProductsCount) >
        Number(onboardingStore?.selectedPlan?.threshold)
      );
    },
  };

  params =
    params || qs.parse(window.location.search, { ignoreQueryPrefix: true });
  if (params.skus) {
    const skus = (params[BUNDLE_SKUS_PARAM] as string).split(",").map(trim);
    const quantities = params.quantities
      ? (params[BUNDLE_QUANTITIES_PARAM] as string).split(",").map(trim)
      : [];
    preset.items = zip(skus, quantities)
      .filter((item) => item[0] !== undefined)
      .map((item) => ({
        sku: item[0] as string,
        quantity: item[1] ? parseInt(item[1], 10) : 1,
      }));
    return preset;
  }
  return null;
};
