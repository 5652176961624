import type { CartInterface, CartItemInterface ,
  TrackedCartInterface,
  TrackedCartItemInterface,
} from "@/types/cart";

export const handle = (cart: CartInterface): TrackedCartInterface => {
  let totalQuantity = 0;
  const items = cart.items.map(
    (item: CartItemInterface): TrackedCartItemInterface => {
      totalQuantity += item.quantity;
      return {
        category: item.category.name,
        sku: item.sku,
        name: item.name,
        totalCost: item.totalCost,
        totalCostBeforeDiscount: item.totalCostBeforeDiscount,
        quantity: item.quantity,
        price: item.price.price,
      };
    }
  );

  return {
    id: cart.id,
    totalCost: cart.cost.total,
    promoCodeDiscountAmount: cart.cost.discount,
    giftCardsAmount: cart.cost.gift,
    taxAmount: cart.cost.tax,
    taxAmountBeforeDiscount: cart.cost.taxBeforeDiscount,
    itemsCost: cart.cost.items,
    shippingCost: cart.cost.shipping,
    orderValueDiscountAmount: cart.cost.orderValueDiscount,
    referralCreditsDiscountAmount: cart.cost.referralCredits,
    promoCode: cart?.promoCode?.code,
    items,
    totalItems: items.length,
    totalQuantity,
  };
};
