import { defineStore } from "pinia";

import {
  getUser,
  login,
  loginInitially,
  logout,
  requestResetPassword,
  resetPassword,
  verify,
} from "@/services/api/auth";
import { clearImpersonate } from "@/services/storage";
import { UPDATE_AUTH_STATE } from "@/stores/actions";
import { track as trackUser } from "~/services/events/track/user/discovered";
import { URL_ACCOUNT } from "~/constants/urls";
import type {
  AuthState,
  LoginActionResponse,
  LoginParams,
  ResetPasswordParams,
  VerificationParams,
} from "~/types/auth";

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    id: null,
    email: null,
    referralId: null,
    profile: null,
    subscription: null,
    address: null,
  }),
  getters: {
    isLoggedIn: (state) => Boolean(state.id),
    userId: (state) => state.id,
    user: (state) => state,
    isCancelled: (state) => state.subscription?.status === "cancelled",
  },
  actions: {
    async [VERIFY]({ code, verificationId }: VerificationParams) {
      const response = await verify(code, verificationId);

      if (response.authenticated && this.email) {
        trackUser(this.email, true);
      }

      this.CLEAR_IMPERSONATE();

      return response;
    },

    async [LOGIN]({
      email,
      password,
    }: LoginParams): Promise<LoginActionResponse> {
      const responseData = await login(email, password);

      // Handle verification case
      if (responseData.nextAction === "verification") {
        const router = useRouter();
        router.push(
          `/onboarding/verification/?verificationId=${responseData.verificationId}`
        );
        return {
          ...responseData,
          redirect: false,
        };
      }

      // Handle admin redirect
      if ("is_admin" in responseData && responseData.is_admin) {
        window.location.href = URL_ACCOUNT.replace("account.", "admin.");
        return {
          ...responseData,
          redirect: false,
        };
      }

      // Handle error case
      if ("error" in responseData || "errors" in responseData) {
        return responseData;
      }

      // Handle successful login
      trackUser(email, true);
      this.CLEAR_IMPERSONATE();

      return responseData;
    },

    async [LOGIN_INITIALLY](token: string) {
      await loginInitially(token);
    },

    async [GET_USER]() {
      try {
        const user = await getUser();
        if (user !== null) {
          this[UPDATE_AUTH_STATE](user);
        }
      } catch (e) {}
    },

    [REQUEST_RESET_PASSWORD](email: string) {
      return requestResetPassword(email);
    },

    async [RESET_PASSWORD]({ email, password, token }: ResetPasswordParams) {
      const cartStore = useCartStore();
      const onboardingStore = useOnboardingStore();

      await resetPassword(email, password, token);
      await this[GET_USER]();
      cartStore[CLEAR_CART]();
      onboardingStore[CLEAR_ABANDONED_CART]();
    },

    [CLEAR_IMPERSONATE]() {
      clearImpersonate();
    },

    async [LOGOUT]() {
      const onboardingStore = useOnboardingStore();
      if (this.email) {
        trackUser(this.email, false);
      }
      await logout();
      this[UPDATE_AUTH_STATE]({ id: null, email: null });
      onboardingStore[CREATE_ABANDONED_CART]();
    },

    [UPDATE_AUTH_STATE](stateUpdate: Partial<AuthState>) {
      this.$patch(stateUpdate);
    },
  },
});
