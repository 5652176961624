import type { AbandonedCartInterface } from "~/types/cart";

const transform = (
  abandonedCart: AbandonedCartInterface
): {
  profileId: null | number;
  priceVarianceGroupId: null | number;
  abandonedCartId: null | number;
  address: {
    line1: null | string;
    line2: null | string;
    city: null | string;
    state: null | string;
    zip: null | string;
    country: null | string;
  };
  firstName: null | string;
  lastName: null | string;
  email: null | string;
  phoneNumber: null | string;
} => ({
  profileId: abandonedCart.profileId,
  priceVarianceGroupId: abandonedCart.priceVarianceGroupId,
  abandonedCartId: abandonedCart.abandonedCartId,
  address: {
    line1: abandonedCart.addressLine1,
    line2: abandonedCart.addressLine2,
    city: abandonedCart.city,
    state: abandonedCart.stateId,
    zip: abandonedCart.zipCodeId,
    country: "USA",
  },
  firstName: abandonedCart.firstName,
  lastName: abandonedCart.lastName,
  email: abandonedCart.email,
  phoneNumber: abandonedCart.phone,
});

export { transform };
