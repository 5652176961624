import eventsManager from "@/services/events/eventsManager";
import { handle as makeTrackedCart } from "~/services/events/track/cart/factories/trackedCartFromCartFactory";
import type { CartInterface, CartItemDifferenceInterface } from "@/types/cart";

const track = async (
  eventId: string,
  difference: CartItemDifferenceInterface,
  cart: CartInterface
) => {
  await eventsManager.push("productAddedToCart", {
    env: import.meta.env.VITE_NODE_ENV,
    productAddedToCartSku: difference.sku,
    productAddedToCartName: difference.name,
    productAddedToCartCategory: difference.categoryName,
    productAddedToCartPrice: difference.price,
    productAddedToCartTotal: difference.total,
    productAddedToCartQuantity: difference.quantityAbsolute,
    productAddedToCartEventId: eventId,
    cart: makeTrackedCart(cart),
    conversion: {
      eventId,
    },
  });
};

export { track };
