import DomainEvent from "./DomainEvent";

export default class InitializedCheckout extends DomainEvent {
  get name() {
    return "initialized-checkout";
  }

  get url() {
    return "/api/marketing/events/initialized-checkout";
  }
}
