import { ROUTE_SIGNUP, ROUTE_PLANS, ROUTE_MENUS } from "@/constants/routes";
import type { BundleItem, BundlePreset } from "~/types/bundle";
import { AxiosError } from "axios";
import type { LocationQueryRaw } from "vue-router";


/**
 * The AbandonedCart class is designed to manage the state and logic for handling
 * customers' abandoned shopping carts. The commented-out code in the class represents
 * functionality that was previously utilized with Vuex for state management within the application.
 * In light of transitioning to using Pinia as the primary state management solution,
 * this code requires refactoring to be compatible with Pinia's approach and architecture.
 * The migration from Vuex to Pinia involves adapting the state management patterns
 * to fit Pinia's stores and leveraging its features for a more modular and maintainable codebase.
 */

export default class AbandonedCart {
  state: any;

  firstName: string | null;

  lastName: string | null;

  stateId: string | null;

  constructor(state: any) {
    this.state = state;
    this.firstName = null;
    this.lastName = null;
    this.stateId = null;
  }

  getStarted() {
    if (!this.hasLogin()) {
      window.location.href = ROUTE_SIGNUP;
    }

    if (!this.hasPlan()) {
      window.location.href = ROUTE_PLANS;
    }

    window.location.href = ROUTE_MENUS;
  }

  async addProductsPreset(preset: BundlePreset, sourceUrl: string) {
    const router = useRouter();
    const onboardingStore = useOnboardingStore();
    const menusStore = useMenusStore();
    const cartStore = useCartStore();
    menusStore.SET_PENDING_ITEMS_PRESET(null);

    if (!onboardingStore.email || !onboardingStore.zipCodeId) {
      menusStore.SET_PENDING_ITEMS_PRESET(preset);
      menusStore.SHOW_CAPTURE_EMAIL_POPUP(true);
    }

    // let's upgrade the plan if needed
    if (preset.requiresPlanUpgrade()) {
      const nextPlan = onboardingStore.plans.find(
        (plan) =>
          plan.threshold >=
          preset.getTotalQuantityAfterApplied(cartStore.cartProductsCount)
      );
      await onboardingStore.UPDATE_PLAN({ planId: nextPlan?.id, sourceUrl });

      const newQueryParams = { ...router.currentRoute.value.query, plan: nextPlan?.slug };
 
      // Update the url to the new plan
      router.replace({
        query: newQueryParams,
      });
    }

    menusStore.SET_PENDING_CART_ITEMS(preset.items);

    for (let i = 0; i < preset.items.length; i++) {
      try {
        await this.addProduct(preset.items[i], sourceUrl);
      } catch (e: unknown) {
        // keep adding products from the bundle even though some of them are off
        if (
          e instanceof AxiosError &&
          (e.response?.status === 404 || e.response?.status === 422)
        ) {
          continue;
        }
        throw e;
      }
    }
  }

  async addProducts(items: BundleItem[], sourceUrl: string) {
    for (const item of items) {
      await this.addProduct(item, sourceUrl);
    }
  }

  async addProduct(item: BundleItem, sourceUrl: string) {
    const menusStore = useMenusStore();
    const cartStore = useCartStore();
    const onboardingStore = useOnboardingStore();

    const route = useRoute();
    const router = useRouter();

    // if no plan selected - ho select one
    if (!this.hasPlan()) {
      const query: LocationQueryRaw = {};
      query.navItem = route.query.navItem;
      return router.push({
        path: ROUTE_PLANS,
        query,
      });
    }

    // maybe you don't have email? enter one
    if (!onboardingStore.email || !onboardingStore.zipCodeId) {
      menusStore.SHOW_CAPTURE_EMAIL_POPUP(true);
    }
    // ok, let's add it
    await cartStore.ADD_PRODUCT({
      sku: item.sku,
      sourceUrl,
      qty: item.quantity,
    });
  }

  hasLogin() {
    const onboardingStore = useOnboardingStore();

    return onboardingStore.email !== null && onboardingStore.zipCodeId !== null;
  }

  hasPlan() {
    const onboardingStore = useOnboardingStore();

    return onboardingStore.planId && Number(onboardingStore.planId) > 0;
  }
}
